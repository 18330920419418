import { PlaylistMetadata } from "@/types";

 const parsePlaylistPlaybackRate = (metadata?: PlaylistMetadata) => {
  const DEFAULT_RATE = 1.0;
  const MIN_RATE = 0.1;
  const MAX_RATE = 24;

  try {
    const rateParsed = parseFloat(metadata?.playback_rate as string);
    return (Number.isFinite(rateParsed) && rateParsed > MIN_RATE && rateParsed <= MAX_RATE)
      ? rateParsed
      : DEFAULT_RATE;
  } catch (err) {
    return DEFAULT_RATE;
  }
};

export { parsePlaylistPlaybackRate };