import { allowedMimeTypes } from "@/config";
import { t } from "i18next";
import { FileIcon, Share, Trash } from "lucide-react";
import { FC, Fragment, memo, MouseEventHandler } from "react";
import { useDropzone } from 'react-dropzone';

// !TODO: move to helpers
const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
};

// !TODO: accepted files format as a prop with the maxSize of the file (512 MB?)
type DropzonePropsType = {
  onChange: (files: File[]) => void;
  onRemoveFile: (file: File) => void;
  acceptedFiles: File[];
  disabled: boolean;
  mimeTypesAllowed?: string[],
  dropAreaLabel?: string;
};

// !TODO: conainer styles
const Dropzone: FC<DropzonePropsType> = ({
  onChange,
  onRemoveFile,
  acceptedFiles,
  disabled,
  mimeTypesAllowed = allowedMimeTypes,
  dropAreaLabel = t("common.dragAndDropAreaLabel"),
}) => {
  // const [myFiles, setMyFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    onChange(acceptedFiles);
    // setMyFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    onDrop,
    disabled,
    // accept: {"image/png": [".png"]}
    // maxSize
  });

  const onRemoveFileClick: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!disabled) {
      onRemoveFile(acceptedFiles[0]);
    }
    // setMyFiles(myFiles.filter((file) => file !== myFiles[0]));
  };

  return (
    <div
      className={`
        ${isDragActive ? "bg-opacity-60" : "bg-opacity-100"}
        cursor-pointer
        border-[1px]
        ${acceptedFiles.length === 0 ? "border-[#1949A3]" : "border-[#858992]"}
        ${acceptedFiles.length === 0 ? "border-dashed" : "border-solid"}
        h-16
        content-center
        bg-[#E8EDF6]
        rounded-xl
        px-[12px]
        text-center
        items-center
        whitespace-nowrap
        overflow-hidden
        flex
      `}
      {...getRootProps()}
    >
      {acceptedFiles.length === 0 ? (
        <Fragment>
          <input {...getInputProps({ disabled: false })} />
          <div>
            <Share
              color="#11203D"
              size={24}
              strokeWidth={2}
            />            
          </div>
          <div className="flex flex-1 items-start flex-col ml-2 mr-4 ml-2 w-full">
            <p className="font-normal text-sm text-[#11203D] mb-1 truncate">
              {dropAreaLabel}
            </p>
            {/* absolute */}
            <p className="truncate flex text-xs font-normal text-[#858992]">
              {mimeTypesAllowed.slice(0,2).join(', ')}
            </p>
          </div>
        </Fragment>
      ) : (
        <div className="flex items-center w-full h-full relative overflow-hidden">
          <div>
            <FileIcon
              strokeWidth={1}
              size={32}
              color="#11203D"
            />
          </div>
          <div className="flex flex-col ml-2 mr-4 items-start overflow-hidden w-full">
            <span className="font-normal text-sm text-[#11203D] mb-1 truncate">
              {acceptedFiles[0].name}
            </span>
            <span className="truncate flex text-xs font-normal text-[#858992]">
              {formatBytes(acceptedFiles[0].size)}
            </span>
          </div>
          <div className="">
            <Trash
              size={24}
              strokeWidth={2}
              color="#858992"
              onClick={onRemoveFileClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Dropzone);
