import {
  FC,
  memo,
  useCallback,
  useState,
} from "react";
import { t } from "i18next";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import Dropzone from "../../../common/Dropzone";
import { MediaContentDataType } from "@/types";
import AddContentFormSchema from "./AddContentFormSchema";
import { getFilesMediaType, getMockFileFromBlobData, getMockFileFromContent } from "./helpers";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import TagsMultiSelect from "@/components/common/TagsMultiSelect";
import {
  // apiCompleteUploadMediaResourceBlob,
  apiCreateMediaResource,
  apiCreateMediaResourceBlobUploadSasUrl,
  apiRemoveMediaResourceBlob,
  apiUpdateMediaResource,
  apiUploadMediaResourceBlobBySasUrl
} from "@/api";

export type AddContentDialogPropsType = {
  onOpenClose: (isOpen: boolean) => void;
  apiPrefix: string;
  parentCategoryId?: string;
  selectedContent?: MediaContentDataType,
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
};

const AddContentDialog: FC<AddContentDialogPropsType> = ({
  onOpenClose,
  apiPrefix,
  parentCategoryId,
  selectedContent,
  onSubmit,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<z.infer<typeof AddContentFormSchema>>({
    resolver: zodResolver(AddContentFormSchema),
    defaultValues: {
      fileName: selectedContent?.name ?? "",
      tags: selectedContent?.tags ?? [],
      file: getMockFileFromContent(selectedContent), // !TODO
      alt_file: selectedContent?.alt_media
        ? getMockFileFromBlobData(
            selectedContent.alt_media.blob_name,
            selectedContent.alt_media.metadata
          )
        : [], 
    },
  });

  const uiConfig = !!selectedContent
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const onSubmitForm = async (formData: z.infer<typeof AddContentFormSchema>) => {
    if (submitFetching) {
      return;
    }

    try {      
      setSubmitFetching(true);
      
      const formFile = formData.file[0];
      const formAltFile = formData.alt_file[0];
      // is always changed if new resource is creating
      const fileChanged = selectedContent ? formFile.lastModified !== 0 : true;
      const altFileChanged = formAltFile && formAltFile.lastModified !== 0; 

      const media_type = (selectedContent && !fileChanged)
        ? selectedContent.media_type
        : getFilesMediaType(formData.file);

      if (!media_type) {
        throw new Error("Invalid media type");
      }

      const mediaResourceUsed = selectedContent || await apiCreateMediaResource(
        apiPrefix,
        parentCategoryId ?? "root",
        {
          tags: formData.tags,
          category_id: parentCategoryId,
          name: formData.fileName,
          media_type,
        }
      );

      let uploadMainBlobResult = null;

      if (fileChanged) {
        uploadMainBlobResult = await apiCreateMediaResourceBlobUploadSasUrl(
          apiPrefix,
          mediaResourceUsed.id,
          parentCategoryId ?? "root",
          formFile.name,
        );

        // !TODO: progress feature
        await apiUploadMediaResourceBlobBySasUrl(
          uploadMainBlobResult.blobUploadSasUrl,
          formFile
        );
      }

      let uploadAltBlobResult = null;

      if (altFileChanged) {
        uploadAltBlobResult = await apiCreateMediaResourceBlobUploadSasUrl(
          apiPrefix,
          mediaResourceUsed.id,
          parentCategoryId ?? "root",
          formAltFile.name,
          "alt_"
        );

        await apiUploadMediaResourceBlobBySasUrl(
          uploadAltBlobResult.blobUploadSasUrl,
          formAltFile
        );
      }

      // remove alternative media blob if needed
      let removaAltBlobResult = false;

      if (!formAltFile && selectedContent?.alt_media?.blob_name) {
        removaAltBlobResult = await apiRemoveMediaResourceBlob(
          apiPrefix,
          mediaResourceUsed.id,
          parentCategoryId ?? "root",
          selectedContent.alt_media.blob_name
        );
      }

      await apiUpdateMediaResource(
        apiPrefix,
        mediaResourceUsed.id,
        parentCategoryId ?? "root",
        {
          category_id: parentCategoryId,
          name: formData.fileName,
          tags: formData.tags,
          media_type,
          ...(uploadMainBlobResult && {
            blob_name: uploadMainBlobResult.blobName, 
            blob_url: uploadMainBlobResult.blobPublicSasUrl, 
            metadata: {
              fileName: formFile.name,
              fileSize: formFile.size,
              fileType: formFile.type,
            },
          }),
          ...(uploadAltBlobResult && {
            alt_media: {
              media_type,
              blob_name: uploadAltBlobResult.blobName,
              blob_url: uploadAltBlobResult.blobPublicSasUrl,  
              metadata: {
                fileName: formAltFile.name,
                fileSize: formAltFile.size,
                fileType: formAltFile.type,
              }            
            }
          }),
          ...(removaAltBlobResult && { alt_media: null })
        },
      );
 
      onOpenChange(false);
      onSubmit(null, uiConfig.submitSuccessMessage);
    } catch (err) {
      console.error(err);
      onSubmit(uiConfig.submitErrorMessage);
    } finally {
      setSubmitFetching(false);
    }
  };

  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault() 
  }, []);

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        <DropdownMenuItem
          disabled={disabled}
          className="w-full flex py-0 px-[8px]"
          onSelect={(e) => e.preventDefault()}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
            <span>{uiConfig.dialogTriggerLabel}</span>
          </div>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent
        onPointerDownOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        className="flex flex-col w-[436px]"
      >
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="fileName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="filename"
                  >
                    {dialogUiConfig.nameFieldLabel}
                  </FormLabel>
                  <FormControl id="filename">
                    <Input
                      className="focus-visible:ring-0"
                      id="filename"
                      placeholder={dialogUiConfig.nameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="tags"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel className="mb-[6px]" htmlFor="tags">
                    {dialogUiConfig.tagsFieldLabel}
                  </FormLabel>
                  <FormControl id="tags">
                    <Controller
                      {...field}
                      render={({ field: { onChange, onBlur, value }}) => (
                        <TagsMultiSelect
                          onOpenClose={setPopoverOpen}
                          // !FIXME
                          placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
                          defaultSelectedValues={value}
                          disabled={submitFetching}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              name="file"
              render={({ field: { value, onChange, ...fieldProps } }) => {
                return (
                  <FormItem className="mb-1">
                    <FormControl>
                      <Dropzone
                        disabled={submitFetching}
                        {...fieldProps}
                        acceptedFiles={form.watch('file')}
                        // !TODO
                        onRemoveFile={(fileToRemove: File) => {
                          form.setValue(
                            "file",
                            form.getValues("file").filter((file: File) => file !== fileToRemove)
                          );

                          form.setValue("alt_file", []);
                        }}
                        onChange={onChange}
                      />
                    </FormControl>
                    <div className="flex h-[20px] overflow-hidden">
                      <FormMessage className="truncate" />
                    </div>
                  </FormItem>
                );
              }}
            />
            {(form.watch("file").at(0)?.type === "video/mp4") && (
              <FormField
                name="alt_file"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <FormControl>
                      <Dropzone
                        {...fieldProps}
                        disabled={submitFetching}
                        acceptedFiles={form.watch("alt_file")}
                        onChange={onChange}
                        // !TODO
                        onRemoveFile={(fileToRemove: File) => {
                          form.setValue(
                            "alt_file",
                            form.getValues("alt_file").filter((file: File) => file !== fileToRemove)
                          );
                        }}
                        mimeTypesAllowed={["video/mp4"]}
                        dropAreaLabel="Additional video to display in portrait mode" // !TODO: label
                      />
                    </FormControl>
                    <div className="flex h-[20px] overflow-hidden">
                      <FormMessage className="truncate" />
                    </div>
                  </FormItem>
                )}
              />
            )}
          </form>
        </Form>
        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddContentDialog);
