/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DataTable } from "@/components/ui/data-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import moment from "moment";
import { Badge } from "@/components/ui/badge";
import { usePermissions } from "app/hooks/permissions";
import { Event } from "app/utils/event";
import { useToast } from "@/components/ui/use-toast";
import { useError } from "app/utils";
import { AuthContext } from "app/providers/auth";
import { ViewContext } from "components/view/view";
import { renderColumnHeader } from "@/components/common/helpers";

const isRequestBtnHidden = false; // !FOR TEST

export function Users(props) {
  const { t } = useTranslation();
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const permissions = usePermissions();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [handleError] = useError();
  const { toast } = useToast();

  async function requestUpdate() {
    await axios({
      url: "/api/utility/autoupdate",
      method: "post",
      data: {
        version: null,
        userId: null,
      },
    });
  }

  function invite() {
    context.modal.show(
      {
        title: t("accountScreen.users.create.title"),
        form: {
          email: {
            label: t("accountScreen.users.create.email"),
            type: "text",
            required: true,
          },
          permission: {
            label: t("accountScreen.users.create.permission"),
            type: "select",
            default: "doctor",
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "owner" &&
                x.value !== "developer" &&
                x.value !== "user" &&
                x.value !== "patient"
            ),
          },
        },
        buttonText: t("accountScreen.users.create.button"),
        text: t("accountScreen.users.create.description"),
        url: "/api/invite",
        method: "POST",
      },
      (form, res) => {
        // add the invited user to the
        if (res.length) {
          const state = [...users];

          res.forEach((invite) => {
            if (!state.find((x) => x.id === invite.id)) {
              state.push({
                id: invite.id,
                avatar: invite.avatar,
                name: "",
                email: invite.email,
                last_active: invite.date_sent,
                permission: invite.permission || "doctor",
                status: t("accountScreen.users.create.statusInvited"),
                disabled: false,
                actions: {
                  invite: resendInvite,
                  delete: deleteInvite,
                },
              });
            }
          });

          Event("invited_user");
          setUsers(state);
        }
      }
    );
  }

  function editUser(data, callback) {
    context.modal.show(
      {
        title: t("accountScreen.users.update.title"),
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: t("accountScreen.users.update.name"),
            type: "text",
            required: true,
            value: data.name,
            errorMessage: t("accountScreen.users.update.nameError"),
          },
          email: {
            label: t("accountScreen.users.update.email"),
            type: "email",
            value: data.email,
            required: true,
          },
          disabled: {
            label: t("accountScreen.users.update.disabled"),
            type: "switch",
            default: data.disabled,
          },
          permission: {
            label: t("accountScreen.users.update.permission"),
            type: data.permission === "owner" ? null : "select",
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "owner" &&
                x.value !== "developer" &&
                x.value !== "user" &&
                x.value !== "patient"
            ),
            default: data.permission,
          },
        },
        buttonText: t("accountScreen.users.update.button"),
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        toast({
          title: t("accountScreen.users.update.message", { name: data.name }),
        });

        if (callback) {
          callback(res);
        }
        
      }
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: t("accountScreen.users.delete.title"),
        form: {},
        buttonText: t("accountScreen.users.delete.button"),
        text: t("accountScreen.users.delete.description", { name: data.name }),
        url: `/api/user/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        toast({
          title: t("accountScreen.users.delete.message", { name: data.name }),
        });
        callback();
      }
    );
  }

  function deleteInvite(data, callback) {
    context.modal.show(
      {
        title: t("accountScreen.users.deleteInvite.title"),
        form: {},
        buttonText: t("accountScreen.users.deleteInvite.button"),
        text: t("accountScreen.users.deleteInvite.description"),
        url: `/api/invite/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        toast({
          title: t("accountScreen.users.deleteInvite.message"),
        });
        // remove from state
        const s = [...users];
        s.splice(
          s.findIndex((u) => u.id === data.id),
          1
        );
        setUsers(s);

        // remove from table
        callback();
      }
    );
  }

  async function resendInvite(data, callback) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });

      toast({
        title: t("accountScreen.users.resendInvite", { email: data.email }),
      });
      callback();
    } catch (err) {
      handleError(err);
    }
  }

  const fetchUsers = async () => {
    setLoading(true);
    const res = await axios.get("/api/account/users");
    // format the user list
    let list = [];

    if (res?.data?.data?.users?.length) {
      list = res.data.data.users
        .map((x) => {
          return {
            id: x.id,
            avatar: x.avatar,
            name: x.name,
            email: x.email,
            last_active: x.last_active,
            permission: x.permission,
            status: x.verified
              ? t("accountScreen.users.statusVerified")
              : t("accountScreen.users.statusRegistered"),
            disabled: x.disabled,
          };
        })
        .filter((user) => user.permission !== "patient");
    }

    if (res?.data?.data?.invites?.length) {
      res.data.data.invites.forEach((x) => {
        list.push({
          id: x.id,
          avatar: x.avatar,
          name: "",
          email: x.email,
          permission: x.permission || "doctor",
          status: t("accountScreen.users.statusInvited"),
          disabled: false,
        });
      });
    }

    setLoading(false);
    setUsers(list);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // attach the per row actions for invites
  if (users.length) {
    users.forEach((u) => {
      if (u.status === t("accountScreen.users.statusInvited")) {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        };
      }
    });
  }

  return (
    <Card>
      <CardHeader className="px-7">
        <CardTitle>{t("accountScreen.users.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable
          defaultSortingState={[{ id: "name", desc: false }]}
          searchField="name"
          actionButtons={[
            (authContext.permission.master && !isRequestBtnHidden) && (
            <Button className="gap-1" onClick={requestUpdate}>
              <PlusCircle className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                {t("accountScreen.users.requestUpdateButton")}
              </span>
            </Button>),
            <Button className="gap-1" onClick={invite}>
              <PlusCircle className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                {t("accountScreen.users.addUserButton")}
              </span>
            </Button>
          ]}
          columns={[
            {
              accessorKey: "name",
              sortUndefined: 1,
              header: renderColumnHeader,
            },
            {
              accessorKey: "email",
              header: renderColumnHeader,
            },
            {
              accessorKey: "last_active",
              header: renderColumnHeader,
              sortingFn: "datetime",
              sortUndefined: -1,
              cell: ({ row }) => {
                const user = row.original;
                return (
                  <span>
                    {moment(user.last_active).format("YYYY-MM-DD HH:MM")}
                  </span>
                );
              },
            },
            {
              accessorKey: "permission",
              header: renderColumnHeader,
              cell: ({ row }) => {
                const user = row.original;
                return <span>{t(`permission.${user.permission}`)}</span>;
              },
            },
            {
              accessorKey: "status",
              header: renderColumnHeader,
              cell: ({ row }) => {
                const user = row.original;
                var statusColor = "secondary";
                if (user.status === t("accountScreen.users.statusRegistered")) {
                  statusColor = "default";
                } else if (
                  user.status === t("accountScreen.users.statusInvited")
                ) {
                  statusColor = "destructive";
                }

                return <Badge variant={statusColor}>{user.status}</Badge>;
              },
            },
            {
              accessorKey: "disabled",
              header: renderColumnHeader,
              enableSorting: false,
              cell: ({ row }) => {
                const user = row.original;
                return (
                  <span>
                    {t(
                      `accountScreen.users.table.disableStatus.${user.disabled}`
                    )}
                  </span>
                );
              },
            },
            {
              id: "actions",
              enableHiding: false,
              cell: ({ row }) => {
                const patient = row.original;

                return (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button aria-haspopup="true" size="icon" variant="ghost">
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel>
                        {t("accountScreen.users.table.actions")}
                      </DropdownMenuLabel>
                      {patient.status ===
                      t("accountScreen.users.statusInvited") ? (
                        <>
                          <DropdownMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              resendInvite(patient, () => {
                                fetchUsers();
                              });
                            }}
                          >
                            {t("accountScreen.users.table.resend")}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteInvite(patient, () => {
                                fetchUsers();
                              });
                            }}
                          >
                            {t("accountScreen.users.table.delete")}
                          </DropdownMenuItem>
                        </>
                      ) : (
                        <>
                          <DropdownMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              editUser(patient, () => {
                                fetchUsers();
                              });
                            }}
                          >
                            {t("accountScreen.users.table.edit")}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteUser(patient, () => {
                                fetchUsers();
                              });
                            }}
                          >
                            {t("accountScreen.users.table.delete")}
                          </DropdownMenuItem>
                        </>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                );
              },
            },
          ]}
          data={users}
          onRowClick={(rowData) => {
            editUser(rowData, () => {
              fetchUsers();
            })
          }}
        />
      </CardContent>
    </Card>
  );
}
