import { t } from "i18next";
import { z } from "zod";
import dialogUiConfig from "./dialogUiConfig";
import { allowedMimeTypes, MAX_MEDIA_FILE_SIZE_MB } from "@/config";

const AddContentFormSchema = z.object({
  fileName: z.string().min(1, {
    message: t("adminScreen.accounts.nameValidationMessage"),
  }),
  // !TODO: validate tags type
  tags: z.array(z.string()).optional(),
  file: z
    .array(z.instanceof(File))
    .refine(
      (files) => files.length > 0,
      { message: dialogUiConfig.fileFieldRequiredValidationMsg },
    )
    .refine((files) => files.every(
      (file) => allowedMimeTypes.includes(file.type)),
      { message: dialogUiConfig.fileFieldTypeValidationMsg },
    )
    .refine((files) => files.every(
      (file) => file.size < MAX_MEDIA_FILE_SIZE_MB * 1024 * 1024),
      { message: dialogUiConfig.fileFieldSizeValidationMsg },
    ),
  alt_file: z
    .array(z.instanceof(File))
    .refine((files) => files.every(
      (file) => ["video/mp4"].includes(file.type)),
      { message: dialogUiConfig.fileFieldTypeValidationMsg }, // !FIXME
    )
    .refine((files) => files.every(
      (file) => file.size < MAX_MEDIA_FILE_SIZE_MB * 1024 * 1024),
      { message: dialogUiConfig.fileFieldSizeValidationMsg },
    ), 
});

export default AddContentFormSchema;